import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="The Best Smiles On The Bachelorette | The Smile Stylist Blog" 
    description="It’s a little known secret that most of the cast have had their smiles re-done. Here are the top smile makeovers and porcelain veneers featured on the Bachelorette.    " 
    keywords="Smile Makeover" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Who has the best smiles on the bachelorette?</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>Season 14 of the Bachelorette is just getting started, and the team at The Smile Stylist has taken it upon themselves to craft this year’s list of the top smiles. Could it be Chris Harrison… well no because he is the host, but we have the top five smiles on this season the Bachelorette ranked. Let’s get started!
            </p>
            <img src={ require("../../images/desktop/Mike Bachelorette.png" )} alt="Mike" className="bl__img" />
            <p>Mike is a dream boat, he might even be called Flavio in disguise, but one thing that he does have for sure is an amazing smile. His teeth are white as snow, and with his perfectly symmetrical smile, it is no wonder he is a popular pick with the ladies. Just imagine waking up to him and his bulldog Riggins after a night out, the only downside is you might be in Ohio, but the smile might make the state worth it.
            </p>
            <img src={ require("../../images/desktop/Rickey.png" )} alt="Rickey" className="bl__img" />
            <p>It is something about a man’s smile that tells you all you need to know, and Rickey has one of those smiles that your grandma will love. With cute little dimples and a great set of teeth, Rickey is a shoe in for the top four in this year’s rankings. This 27-year-old IT consultant could not be anymore trusting, and with his online personal training company, he will always be smiling at the gym and in life.
            </p>
            <img src={ require("../../images/desktop/Christon.png" )} alt="Christon" className="bl__img" />
            <p>Christon’s smile makes him a man you want to bring home to meet the family, and sneak away for some alone time. His chiseled jaw line, combined with his perfectly straight teeth make for a shocking but eye-catching smile that will impress even the most hardened man. This 31-year-old former basketball player has made thousands smile throughout his career, and it should be no surprise that he finds himself in the top three.
            </p>
            <img src={ require("../../images/desktop/Colton.png" )} alt="Colton" className="bl__img" />
            <p>Colton, the man, the myth and the legend. He rounds out the top two, and as a former professional football player, it is no wonder that his smile is so perfect. He has that look about him that is trusting but fun loving, and that comes out through his great smile. His current work with the cystic fibrosis network has ensured he has a great life after football, and his smile ensures that he will be a great guy to come home to after a long day of work for some wine and cuddles with his dog Sniper.
            </p>
            <img src={ require("../../images/desktop/Darius.png" )} alt="Darius" className="bl__img" />
            <p>When we sat down to make this list, it was really about who was going to place 2-5, as Darius has already won the staff as soon as he showed up on the show. This 26-year-old salesman has been wowing the audience since the first episode, and the main reason is his smile. It is symmetrical, his teeth are pearly white, and it simply leaves you feeling a little bit better about your day. If Darius is not chosen as the Bachelorette winner, the one thing he will leave the show with is knowing that he has won The Best Smile on Season 14 of the Bachelorette, and that is worth its weight in gold!
            </p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Virtual Smile Makeover" src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage